import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../helpers/languageUtils";
import BaseInput from "../../base/BaseInput/BaseInput";
import BaseButton from "../../base/BaseButton/BaseButton";
import { generateQRCode } from "./state/operators";
import { isEmpty } from "../../helpers/formatUtils";
import asurionPay from "../../images/asurion-pay.png";
import PaymentDetails from "./PaymentDetails";
import PaymentReceiveDetails from "./PaymentReceiveDetails";
import verifiedIcon from "../../images/verified.png";
import defaultVerifiedIcon from "../../images/default-verified.png";
import ServiceUnavailable from "../../components/ServiceUnavailable";
import { TextRegular } from "../../base/BaseText";
import BaseLabel from "../../base/BaseLabel";
import Amplify from "aws-amplify";
import Config from "./../Appsync/Config";
import useAppSync from "../Appsync/Chat/useAppSync";
import { setPaymentStatusUpdate, setUserDetailsData } from "./state/actions";
import { resetStore as validationReset } from "./state/reducers";
import { useEffect } from "react";
import { isIOS } from "../../helpers/util";
import SendMixpanelEvent from "../../mixpanel/sendMixpanelEvent";
import EVENTLIST from "../../mixpanel/EVENTLIST";
import MIXPANEL from "../../mixpanel/mixpanelActions";
import { FIELD_IDS } from "../../helpers/constants";

const getAppSyncConfig = (config) => ({
  aws_appsync_graphqlEndpoint: config.AppSync.EndPoint,
  aws_appsync_region: config.AppSync.Region,
  Analytics: {
    disabled: true, // OPTIONAL - disable Analytics if true
  },
  Auth: {
    identityPoolId: config.Cognito.IdentityPoolId,
    region: config.Cognito.Region,
    userPoolId: config.Cognito.UserPoolId,
    userPoolWebClientId: config.Cognito.WebClientId,
  },
});

Amplify.configure(getAppSyncConfig(Config));

const Home = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [caseNumber, setCaseNumber] = useState("");
  const [mdn, setMdn] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [freezeWindow, setFreezeWindow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showScanDetails, setShowScanDetails] = useState(false);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [isOnline, setIsOnline] = useState(null);
  const validation = useSelector((state) => state.validation);

  const {
    // showScanDetails,
    // showPaymentDetails,
    showServiceUnavailable,
  } = validation;

  const mdnLength = 8;
  const caseNumberLength = 6;

  const handleCaseNumberChange = (value) => {
    if (!isNaN(value.trim())) setCaseNumber(value.trim());
  };

  const handleMdnChange = (value) => {
    if (!isNaN(value.trim())) setMdn(value.trim());
  };

  const submitMdn = async () => {
    setFreezeWindow(true);
    setLoading(true);
    let response = await dispatch(generateQRCode(mdn, caseNumber));
    setLoading(false);

    dispatch(
      SendMixpanelEvent(EVENTLIST.GET_QR_API, {
        Data: response,
      })
    );

    if (
      isEmpty(response.GenerateQRResponse.Message) ||
      response.GenerateQRResponse.Message.toUpperCase() === "PENDING"
    ) {
      dispatch(setUserDetailsData(mdn, caseNumber));
      setShowScanDetails(true);
      initSubscription(response.GenerateQRResponse.BillReferenceNumber);
    } else if (
      response.GenerateQRResponse.Message.toUpperCase() === "DUPLICATE"
    ) {
      const paymentStatus = {
        caseNumber: caseNumber,
        mobileNumber: mdn,
        billReferenceNumber: response.GenerateQRResponse.BillReferenceNumber,
        totalAmount: response.GenerateQRResponse.TotalAmount,
        createdAt: response.GenerateQRResponse.createdAt,
        ICN_timeStamp: response.GenerateQRResponse.ICN_timeStamp,
      };
      dispatch(setPaymentStatusUpdate(paymentStatus));
      setShowPaymentDetails(true);
    } else {
      setErrorMessage(response.GenerateQRResponse.Message);
      setFreezeWindow(false);
    }
  };

  const showPaymentDetailsPage = () => {
    setShowPaymentDetails(true);
  };

  const RedirectToHomePage = () => {
    setMdn("");
    setCaseNumber("");
    setFreezeWindow(false);
    setShowScanDetails(false);
    setShowPaymentDetails(false);
    setLoading(false);
    dispatch({ type: validationReset.toString() });
    // endSubscriptions();
  };

  const handleStatusUpdate = (status) => {
    console.log("status is", "::", status);
    setIsOnline(status);
  };

  const handleError = (errorCode) => (errorMsg) => {
    console.log(errorCode, "::", errorMsg);
  };

  const handlePaymentStatusUpdate = (paymentStatus) => {
    console.log(
      `Subscription received : payment status is ${JSON.stringify(
        paymentStatus
      )}`
    );
    dispatch(
      SendMixpanelEvent(EVENTLIST.PAYMENT_STATUS_SUBSCRIPTION, {
        Data: paymentStatus,
      })
    );
    if (
      !isEmpty(paymentStatus.paymentStatus) &&
      paymentStatus.paymentStatus.toUpperCase() === "SUCCESS"
    ) {
      dispatch(setPaymentStatusUpdate(paymentStatus));
      showPaymentDetailsPage();
      endSubscriptions();
    }
  };

  const {
    subscribeToPaymentStatus,
    unSubscribe,
    isSubscriptionActive,
  } = useAppSync({
    handleError,
    handlePaymentStatusUpdate,
    handleStatusUpdate,
  });

  const initSubscription = async (billReferenceNumber) => {
    subscribeToPaymentStatus(billReferenceNumber);
  };

  const getSubscriptionStatus = async () => {
    isSubscriptionActive();
  };

  const endSubscriptions = async () => {
    console.log("unsubscribed method called");
    unSubscribe();
  };

  const appHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const eventListener = () => {
    if (document.visibilityState === "visible") {
      // the page has become visible
      getSubscriptionStatus();
    }
  };

  useEffect(() => {

    MIXPANEL.init(Config.Mixpanel.mixpanelId);
    MIXPANEL.register_once({ "Carrier": EVENTLIST.ASURION_PAYMENT_PORTAL });

    dispatch(SendMixpanelEvent(EVENTLIST.HOME_PAGE))

    appHeight()

    window.addEventListener("resize", appHeight);

    return () => {
      window.removeEventListener("resize", appHeight);
    };
  }, []);

  // useEffect(() => {
  //   //  if(isIOS()) {

  //   document.addEventListener("visibilitychange", eventListener);

  //   return () => {
  //     document.removeEventListener("visibilitychange", eventListener);
  //   };
  //   // }
  // }, []);

  const shouldSubmit =
    freezeWindow ||
    isEmpty(caseNumber) ||
    caseNumber.length < caseNumberLength ||
    isEmpty(mdn) ||
    mdn.length < mdnLength;

  const VerifiedIcon = () => (
    <img className="m-auto" src={verifiedIcon} alt="verified" />
  );

  const DefaultVerifiedIcon = () => (
    <img className="m-auto" src={defaultVerifiedIcon} alt="verified" />
  );

  return (
    <div id="body" className = "body">
      {showServiceUnavailable && <ServiceUnavailable />}

      {!showServiceUnavailable && !showScanDetails && !showPaymentDetails && (
        <div className="text-center">
          <img className="m-auto pt-24" src={asurionPay} alt="logo" />

          <BaseInput
            id={FIELD_IDS.CASE_NUMBER}
            className="mt-10 h-20"
            value={caseNumber}
            placeholder={t("Home.CaseNumberPlaceholder")}
            onChange={handleCaseNumberChange}
            medium
            maxLength={15}
            disabled={freezeWindow}
            autoFocus
            Suffix={
              caseNumber.length >= caseNumberLength
                ? VerifiedIcon
                : DefaultVerifiedIcon
            }
          />

          <BaseInput
            id={FIELD_IDS.MDN}
            className="h-20"
            value={mdn}
            placeholder={t("Home.MdnPlaceholder")}
            onChange={handleMdnChange}
            medium
            maxLength={10}
            disabled={freezeWindow}
            onEnterPress={shouldSubmit ? () => {} : submitMdn}
            Suffix={
              mdn.length >= mdnLength ? VerifiedIcon : DefaultVerifiedIcon
            }
          />

          {!loading && !isEmpty(errorMessage) && (
            <BaseLabel className="w-28 m-auto text-center text-text-error">
              <TextRegular>{errorMessage}</TextRegular>
            </BaseLabel>
          )}

          <BaseButton
            id={FIELD_IDS.SUBMIT}
            className="w-28 m-auto mt-14 font-Apercu-Regular"
            name="Submit"
            text={t(`Home.Submit`)}
            onClick={submitMdn}
            clicked={freezeWindow}
            disabled={shouldSubmit}
            focused
          />
        </div>
      )}

      {!showServiceUnavailable && showScanDetails && !showPaymentDetails && (
        <PaymentDetails
          showPaymentDetailsPage={showPaymentDetailsPage}
          RedirectToHomePage={RedirectToHomePage}
          status={isOnline}
        ></PaymentDetails>
      )}

      {!showServiceUnavailable && showPaymentDetails && (
        <PaymentReceiveDetails
          RedirectToHomePage={RedirectToHomePage}
        ></PaymentReceiveDetails>
      )}
    </div>
  );
};

export default Home;
